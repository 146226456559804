import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { BsInfoCircle } from 'react-icons/bs';
import {
  GiPayMoney,
  GiPunchBlast,
  GiTimeBomb,
} from 'react-icons/gi';
import { GrMore } from 'react-icons/gr';
import { MdOutlineFastfood } from 'react-icons/md';
import {
  Link,
  Outlet,
} from 'react-router-dom';

import {
  searchFood,
  searchNutrients,
} from '../api';
import {
  CacheContext,
  FoodContext,
} from '../context';

export const FoodSearch = (props) => {

    let [view, setView] = useState('search');
    let [nutrient, setNutrient] = useState(false);
    let { updated, setUpdated } = useContext(CacheContext);
    let { foodData } = useContext(FoodContext);
    let [foodList, setFoodList] = useState([]);
    let [nutrientsList, setNutrientsList] = useState([]);
    let [keyword, setKeyword] = useState('');
    let [foodResults, setFoodResults] = useState([]);
    let [nutrientsResults, setNutrientsResults] = useState([]);

    const updateKeyword = (e) => {
        setKeyword(e.target.value);
    }

    const runSearch = () => {
        if (keyword && keyword.length > 2) {
            let newFoodResults = searchFood(foodList, keyword);
            setFoodResults(newFoodResults);
            let newNutrientsResults = searchNutrients(nutrientsList, keyword);
            setNutrientsResults(newNutrientsResults);
        }
    }

    const selectNutrient = (name) => {

        let newNutrient = {
            name: name,
            sources: []
        };

        for (let foodItem of foodList) {
            for (let nutrientInfo of foodItem.nutrients) {
                if (nutrientInfo.nutrient === name) {
                    newNutrient.sources.push(foodItem);
                }
            }
        }

        setNutrient(newNutrient);
        setView('details');

    }

    useEffect(() => {

        if (foodData && foodData.food && Object.entries(foodData.food).length > 0) {
            let entries = [];
            for (let [k, v] of Object.entries(foodData.food)) {
                entries.push(v);
            }
            setFoodList([...entries]);
        }

        if (foodData && foodData.nutrients && foodData.nutrients.length > 0) {
            console.info("got nutrients", foodData.nutrients.length);
            setNutrientsList([...foodData.nutrients]);
        }

    }, [foodData]);


    useEffect(() => {

        console.info("N?", nutrient);

    }, [nutrient]);



    return (
        <>
            {'details' === view && nutrient ?
                <NutrientDetails nutrient={nutrient} setView={setView} />
                :
                <Container>
                    <Row className="mb-4">
                        <Col>
                            <Card>
                                <Card.Body>
                                    <h1>
                                        <AiOutlineFileSearch />
                                    </h1>
                                    <Card.Title>Search</Card.Title>

                                    <Form.Group className="mb-3" controlId="keyword">
                                        <Form.Control type="text" placeholder="Enter keyword" value={keyword || ''} onChange={(e) => updateKeyword(e)} />
                                    </Form.Group>

                                    <Button onClick={() => runSearch()} variant="outline-primary">Search</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {foodResults && foodResults.length > 0 &&
                        <>
                            {foodResults.map((entry, index) =>
                                <Row key={index}>
                                    <Col>
                                        <Card>
                                            <Card.Header>
                                                {entry.name} <Badge bg="info" className="float-end">food</Badge>
                                            </Card.Header>
                                            <Card.Body>
                                                <FoodItem nutrients={entry.nutrients} selectNutrient={selectNutrient} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                        </>
                    }
                    {nutrientsResults && nutrientsResults.length > 0 &&
                        <>
                            {nutrientsResults.map((entry, index) =>
                                <Row key={index}>
                                    <Col>
                                        <Card>
                                            <Card.Header>
                                                {entry.nutrient} <Badge bg="warning" className="float-end">nutrient</Badge>
                                            </Card.Header>
                                            <Card.Body>
                                                <p>Daily {parseFloat(entry.grams)}g
                                                    <GrMore className="float-end clicker" onClick={() => selectNutrient(entry.nutrient)} />
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                        </>
                    }
                </Container>
            }
        </>
    )

}

const FoodItem = (props) => {

    let [nutrients, setNutrients] = useState([]);

    useEffect(() => {

        if (props.nutrients) {
            setNutrients([...props.nutrients]);
        }

    }, [props.nutrients]);

    return (
        <>
            {nutrients && nutrients.length > 0 ?
                <ListGroup>
                    {nutrients.map((item, index) =>
                        <ListGroup.Item key={index} variant={props.selection === item.nutrient ? 'info' : 'light'}>
                            <b>{item.nutrient}</b>: {item.pct}%  - {Math.round(item.grams)}g
                            {"g" !== item.unit &&
                                <i>
                                    {' '} or {Math.round(item.quantity)} {item.unit}
                                </i>
                            }
                            {props.selectNutrient &&
                                <GrMore className="float-end clicker" onClick={() => props.selectNutrient(item.nutrient)} />
                            }
                        </ListGroup.Item>
                    )}
                </ListGroup>
                :
                <></>
            }
        </>
    )

}

const NutrientDetails = (props) => {

    let [nutrient, setNutrient] = useState({});

    const resetView = () => {
        setNutrient({});
        props.setView('search');
    }

    useEffect(() => {

        if (props.nutrient) {
            setNutrient({ ...props.nutrient });
        }

    }, [props.nutrient]);


    return (
        <Container>
            <Row className="mb-4">
                <Col>
                    <Card>
                        <Card.Body>
                            <h1>
                                <BsInfoCircle />
                            </h1>
                            <Card.Title>About {nutrient.name}</Card.Title>
                            <Button onClick={() => resetView()} variant="outline-primary">Back</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {nutrient.sources &&
                <>
                    {nutrient.sources.map((source, index) =>
                        <Row key={index}>
                            <Col>
                                <Card>
                                    <Card.Header>
                                        {source.name}
                                    </Card.Header>
                                    <Card.Body>
                                        <FoodItem nutrients={source.nutrients} selection={nutrient.name} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </>
            }
        </Container>
    )

}