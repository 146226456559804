import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Link,
  Outlet,
} from 'react-router-dom';

export const Food = (props) => {
    return (
        <Outlet />
    );
}
