import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import {
  GiPayMoney,
  GiPunchBlast,
  GiTimeBomb,
} from 'react-icons/gi';
import { MdOutlineFastfood } from 'react-icons/md';
import {
  Link,
  Outlet,
} from 'react-router-dom';

import {
  CacheContext,
  FoodContext,
} from '../context';
import { useGet } from '../hooks';

export const Editor = (props) => {
    return (
        <div>
            <h1>editor</h1>
        </div>
    );
}


export const GroceryShopping = (props) => {
    return (
        <div>
            <h1>shopping</h1>
        </div>
    );
}

const TopMenu = (props) => {

    return (

        <Navbar collapseOnSelect expand="lg">
            <Container>
                <Navbar.Brand href="/">retrain</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav>
                        <Nav.Link href="#deets">...</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );



}

export const Screen = (props) => {

    const [updated, setUpdated] = useState(Date.now())
    const [foodState, foodData] = useGet('data/food#' + updated);

    const [food, setFood] = useState({});

    useEffect(() => {
        if ('fetched' === foodState) {
            let newFood = { ...foodData };
            setFood(newFood);
        }
        //console.info(foodState, Date.now());
    }, [foodState]);

    return (
        <>
            <CacheContext.Provider value={{ updated, setUpdated }}>
                <FoodContext.Provider value={{ foodData }}>
                    <TopMenu />
                    <Outlet />
                </FoodContext.Provider>
            </CacheContext.Provider>

        </>
    );
}

export const Welcome = (props) => {

    let { updated, setUpdated } = useContext(CacheContext);

    return (
        <Container>
            <Row>
                <Col>
                    <Card>
                        {/* <Card.Body>
                            <h1>
                                <GiPunchBlast />
                            </h1>
                            <Card.Title>Fight back!</Card.Title>
                            <Card.Text>
                                Tempted by something you know is not good for you? Don't give in too easily.
                            </Card.Text>
                            <Button variant="outline-primary"><MdOutlineFastfood /> Bad food or drink</Button>{' '}
                            <Button variant="outline-primary"><GiPayMoney /> Foolish spendings</Button>{' '}
                            <Button onClick={() => setUpdated(Date.now())} variant="outline-primary"><GiTimeBomb /> Destructive behavior</Button>
                        </Card.Body> */}
                    </Card>
                </Col>
            </Row>
        </Container>

    )

}