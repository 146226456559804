import { render } from 'react-dom';

import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import { Food } from './food';
import { FoodSearch } from './food/search';
import {
  Screen,
  Welcome,
} from './screen/layout';

const rootElement = document.getElementById("magic");
render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Screen />}>
                <Route index element={<Welcome />}></Route>
                <Route path="food" element={<Food />}>
                    <Route path="search" element={<FoodSearch />} />
                </Route>
                {/* <Route path="nutrition" element={<Nutrition />}>
                    <Route path="nutrients" element={<Nutrients />}>
                        <Route path=":nutrientId" element={<Nutrient />} />
                    </Route>
                </Route> */}
                <Route
                    path="*"
                    element={
                        <Welcome />
                    }
                />
            </Route>
        </Routes>
    </BrowserRouter>,
    rootElement
);


