import { createContext } from 'react';

export const CacheContext = createContext({
    _: 0,
    set_: () => { },
});

export const FoodContext = createContext({
    _: {},
    set_: () => { },
});
