import Fuse from 'fuse.js';

export const endpoint = "https://apiv1.retrain.studio";



export const fetchData = async (url, next) => {

    let output = {};

    try {
        const response = await fetch(url);
        output = await response.json();

        if (next) {
            next(output);
        }

    } catch (error) {
        console.error("error", error);
    }

    return output;

};


export const searchFood = (foodList, keyword) => {

    const fuse = new Fuse(foodList, {
        keys: ['name'],
        includeScore: true,
        minMatchCharLength: 2
    })

    let results = fuse.search(keyword);
    let items = [];
    for (let result of results) {
        if (items.length < 5) {
            items.push(result.item);
        }
    }
    return items;

};


export const searchNutrients = (nutrientsList, keyword) => {

    const fuse = new Fuse(nutrientsList, {
        keys: ['nutrient'],
        includeScore: true,
        minMatchCharLength: 2
    })

    let results = fuse.search(keyword);
    let items = [];
    for (let result of results) {
        if (items.length < 10) {
            items.push(result.item);
        }
    }
    return items;

};

