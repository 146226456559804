import {
  useEffect,
  useState,
} from 'react';

import {
  endpoint,
  fetchData,
} from './api';

export const useGet = (uri) => {
    const [activity, setActivity] = useState('idle');
    const [data, setData] = useState([]);

    useEffect(() => {
        if (uri) {
            let url = endpoint + "/" + uri;
            setActivity('fetching');
            fetchData(url, (content) => {
                setData(content);
                setActivity('fetched');
            })

        } else {
            console.warn("no url");
            return;
        }


    }, [uri]);

    return [activity, data];
};
